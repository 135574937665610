/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';

// start the Stimulus application
(function(){
    // ANIMATION POUR LES CHAMPS ERRONES
    //        $('.asterisque').animate({
    //            fontSize : '24px',
    //            top : '-316px'
    //        },1000, function() {
    //            $( this ).animate({
    //                fontSize : '16px'
    //            },400)
    //          })
    //        
    
    
    var formSteps = $('.form-steps')
    var datedepart = $('#datedepart')
    var nom = $('#name')
    var adresse = $('#adresse')
    var mail = $('#mail')
    var telephone = $('#telephone')
    var typevehicule = $("input[name='typevehicule']:checked")
    var etape1 = $('#etape1')
    var etape2 = $('#etape2')
    var etape3 = $('#etape3')
    var nextBtn1 = $('.next-btn', etape1)
    var nextBtn2 = $('.next-btn', etape2)
    var validateNom = $('.validateNom')
    var validateAdresse = $('.validateAdresse')
    var validateDatedepart = $('.validateDatedepart')
    var validateMail = $('.validateMail')
    var validateTypevehicule = $('.validateTypevehicule')
    var validateTelephone = $('.validateTelephone')
    
    //On selectionne tous les champs
    var theme_inputs = $('.form-check-input')
        //nextBtn = $('.next-btn', etape2)
    
    
    //NOM//
    nom.keyup(function(){
        //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur
        if(nom.val().length <= 2){
            validateNom.css('visibility', 'visible')        
        }else {
            validateNom.css('visibility', 'hidden')
        }
    })
    datedepart.keyup(function(){
        //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur
        if(nom.val().length <= 2){
            validateDatedepart.css('visibility', 'visible')        
        }else {
            validateDatedepart.css('visibility', 'hidden')
        }
    })
    
    var CheckNom = function() {
        //On verifie que le champ contient bien des donnÃ©es
            //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
            if(nom.val().length <= 2){
                validateNom.css('visibility', 'visible')
                error_nom = true
            }else {
                validateNom.css('visibility', 'hidden')
            }
    }
    var CheckDatedepart= function() {
        //On verifie que le champ contient bien des donnÃ©es
            //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
            if(datedepart.val().length <= 2){
                validateDatedepart.css('visibility', 'visible')
                error_datedepart = true
            }else {
                validateDatedepart.css('visibility', 'hidden')
            }
    }
   
    //Adresse//
    
    adresse.keyup(function(){
        //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
        if(adresse.val().length <= 2){
            validateAdresse.css('visibility', 'visible')
        }else {
            validateAdresse.css('visibility', 'hidden')
        }
    })
    telephone.keyup(function(){
        //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
        if(telephone.val().length <= 2){
            validateTelephone.css('visibility', 'visible')
        }else {
            validateTelephone.css('visibility', 'hidden')
        }
    })
    
    var CheckAdresse = function() {
        //On verifie que le champ contient bien des donnÃ©es
            //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
            if(adresse.val().length <= 2){
                validateAdresse.css('visibility', 'visible')
                error_adresse = true
            }else {
                validateAdresse.css('visibility', 'hidden')
            }
    }
    var CheckTelephone = function() {
        //On verifie que le champ contient bien des donnÃ©es
            //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
            if(telephone.val().length <= 2){
                validateTelephone.css('visibility', 'visible')
                error_telephone = true
            }else {
                validateTelephone.css('visibility', 'hidden')
            }
    }
    var CheckTypevehicule = function() {
        //On verifie que le champ contient bien des donnÃ©es
            //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
            if(typevehicule.checked() == false){
                validateTypevehicule.css('visibility', 'visible')
                error_typevehicule = true
            }else {
                validateTypevehicule.css('visibility', 'hidden')
            }
    }
    
    //Mail//
    
    mail.keyup(function(){
        //si la valeur est infÃ©rieure Ã  2, on affiche l'erreur et on dÃ©fini error_nom Ã  true
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail.val())){
            validateMail.css('visibility', 'hidden')
        }else {
            validateMail.css('visibility', 'visible')
            error_mail = true;
        }
    })
    
    var CheckMail = function() {
        if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(mail.val())){
            validateMail.css('visibility', 'hidden')
        }else {
            validateMail.css('visibility', 'visible')
            error_mail = true;
        }
    }
    
    nextBtn1.click(function(){
        var error_datedepart = false
        CheckDatedepart();
        
        if(error_datedepart == false){
            $(this).parent().removeClass('active')
            $(this).parent().next().addClass('active')
            $('.steps li').eq(section.index($(this).parent().next())).addClass('active')
            $('.steps li').eq(section.index($(this).parent())).removeClass('active')
        }
    })
    
    
        //Etape 3 : Maintenant il nous faut vÃ©rifier qu'il n'existe pas 2 chiffres identiques car il est aisÃ© d'obtenir le chiffre 15 en additionnant des chiffres similaires
        
        nextBtn2.click(function(){



                $(this).parent().removeClass('active')
                $(this).parent().next().addClass('active')
                $('.steps li').eq(section.index($(this).parent().next())).addClass('active')
                $('.steps li').eq(section.index($(this).parent())).removeClass('active')
        })
    
    //CHECKBOX
        //Dans l'exemple j'ai volontairement mis des checkbox. Le choix le plus judicieux ici aurait Ã©tÃ© de mettre des input de type Radio.
        //Selon le projet et le contexte vous serez surement amenÃ© Ã  bloquer les actions des utilisateurs
    //Je vais vous montrer comment laisser le choix Ã  l'utilisateur de modifier sa rÃ©ponse tout en Ã©vitant par exemple de rajouter un choix par dÃ©faut comme "je ne sais pas".
    var error_nom = false
    var error_adresse = false
    var error_telephone = false
    var error_mail = false
    CheckNom();
    CheckAdresse();
    CheckTelephone();
    CheckMail();
        var inputChek = $('.choix-input input', etape3)
    
        inputChek.click(function(){
            if(error_nom == false && error_adresse == false && error_mail == false){
                
                    return true
                }
            else
            {
                alert('Veuillez remplir correctement le formulaire')
                    return false
                
            }
        })
    
        
        //Comme vous pouvez le constater, ce formulaire Ã  Ã©tape tient sur une seule page sans rafraichissement. Cela signifie que l'utilisateur peut Ã  tout moment valider le formulaire en appuyant sur la touche entrÃ©e par inadvertance.
        //Pour empÃªcher cela, on va bloquer l'action de la touche entrÃ©e sur le formulaire.
    formSteps.keydown(function(e){
        if(e.keyCode==13) {
            alert('Merci de remplir tous les champs avant de soumettre le formulaire') 
            e.preventDefault()
        }
    }
    );
    
    
    
    //La premiÃ¨re Ã©tape va consister Ã  afficher les etapes suivante au click sur le bouton. Pour cela on va rÃ©cupÃ©rer notre bouton et lui appliquÃ© un Ã©vÃ¨nement au clique.
    var steps = $('.step')
    var PrevBtn = $('.previous-btn')
    var section = $('.section')
    
    //nextBtn.click(function(){
    //Au clique, la classe active du parent est retirÃ©e et l'Ã©lÃ©ment suivant gagne cette classe
    
        //    $(this).parent().removeClass('active')
    //    $(this).parent().next().addClass('active')
    
         //On va maintenant gÃ©rÃ© l'affichage des Ã©tapes tout en haut. Au clique il faut donc que les chiffres suivent. Pour se faire, on va les selectionner et dÃ¨s qu'on passe Ã  l'Ã©tape suivante la class active est ajoutÃ© au chiffre correspondant.
    //Ici on a plusieur possibilitÃ©s
        //Tu rÃ©cupÃ¨res l'index du li correspondant Ã  l'index de la section suivante du btn sur lequel je click et tu lui appliques la class active. Ensuite tu retires la class active au li du parent que je viens de masquer.
    
    //    $('.steps li').eq(section.index($(this).parent().next())).addClass('active')
    //    $('.steps li').eq(section.index($(this).parent())).removeClass('active')
    //})
    PrevBtn.click(function(){
    //Au clique, la classe active du parent est retirÃ©e et l'Ã©lÃ©ment suivant gagne cette classe
        $(this).parent().removeClass('active')
        $(this).parent().prev().addClass('active')
    
         //On va maintenant gÃ©rÃ© l'affichage des Ã©tapes tout en haut. Au clique il faut donc que les chiffres suivent. Pour se faire, on va les selectionner et dÃ¨s qu'on passe Ã  l'Ã©tape suivante la class active est ajoutÃ© au chiffre correspondant.
    //Ici on a plusieur possibilitÃ©s
        //Tu rÃ©cupÃ¨res l'index du li correspondant Ã  l'index de la section suivante du btn sur lequel je click et tu lui appliques la class active. Ensuite tu retires la class active au li du parent que je viens de masquer.
    
        $('.steps li').eq(section.index($(this).parent().prev())).addClass('active')
        $('.steps li').eq(section.index($(this).parent())).removeClass('active')
    })
    })()